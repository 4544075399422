import smoothscroll from "smoothscroll-polyfill";
import { useEffect, useRef, useCallback } from "react";
import { useEffectOnce } from "react-use";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import {
  BrowserRouter,
  Route,
  Switch,
  useLocation,
  useHistory,
  withRouter
} from "react-router-dom";
import { ErrorBoundary } from "./components/common/errorBoundary";
import { GlobalStyles } from "./components/common/globalStyles";
import { Layout } from "./components/common/layout";
import { UIContextProvider } from "./contexts/uiContext";

import { useAuth } from "./hooks/useAuth";
import { useAuthHandle } from "./hooks/useAuthHandle";
import {
  GatewayContainer,
  GenericErrorContainer,
  HomeContainer,
  LoginContainer,
  NovelContainer,
  NovelEpisodeReorderContainer,
  EpisodeWriteContainer,
  EpisodePreviewContainer
} from "./routes";

import { AuthUseCase } from "./usecases/authUseCase";

import "./assets/transitions.css";

export const SESSION_KEY = "session";

const authUseCase = new AuthUseCase();

const AnimatedSwitch = withRouter(({ location }) => {
  const prev = useRef<string>();

  useEffect(() => {
    prev.current = location.pathname;
  }, [location]);

  const getPathDepth = useCallback((pathname?: string): number => {
    if (!pathname) {
      return 0;
    }
    let pathArr = pathname.split("/");
    pathArr = pathArr.filter(n => n !== "");
    return pathArr.length;
  }, []);

  const noAnimation = location.search.indexOf("notransition") > -1;
  const toWritePage = location.pathname.indexOf("/episodes/") > -1;
  const fromWritePage = (prev.current || "").indexOf("/episodes/") > -1;
  const enterDirection = fromWritePage || toWritePage ? "up" : "left";
  const exitDirection = fromWritePage || toWritePage ? "down" : "right";
  const direction =
    getPathDepth(location.pathname) - getPathDepth(prev.current) >= 0
      ? enterDirection
      : exitDirection;

  const animationClass = noAnimation ? "nothing" : direction;

  return (
    <TransitionGroup>
      <CSSTransition
        key={location.key}
        classNames="pageSlider"
        timeout={{ enter: 200, exit: 100 }}
      >
        <div className={animationClass}>
          <Switch location={location}>
            <Route exact path="/gateway" component={GatewayContainer} />
            <Route exact path="/login" component={LoginContainer} />
            <Route exact path="/" component={HomeContainer} />
            <Route exact path="/novel/:novelId" component={NovelContainer} />
            <Route
              exact
              path="/novel/:novelId/reorder"
              component={NovelEpisodeReorderContainer}
            />
            <Route
              exact
              path="/novel/:novelId/episodes/:episodeId"
              component={EpisodeWriteContainer}
            />
            <Route
              exact
              path="/preview/:novelId/episodes/:episodeId"
              component={EpisodePreviewContainer}
            />
            <Route
              exact
              path="/error/:type"
              component={GenericErrorContainer}
            />
            <Route path="*">
              <GenericErrorContainer />
            </Route>
          </Switch>
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
});

export const App: React.FC = () => {
  useAuthHandle();
  const { user } = useAuth();
  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");
  const webToken = urlParams.get("webToken");
  const location = useLocation();
  // Add support for smooth scrolling in iOS webview
  useEffectOnce(() => {
    smoothscroll.polyfill();
  });

  // redirect old domain to new domain
  useEffect(() => {
    if (window.location.hostname === "localhost") { return }
    if (window.location.hostname !== process.env.REACT_APP_WEB_DOMAIN) {
      const pathname = window.location.pathname.startsWith("/novel-studio")
        ? window.location.pathname
        : `/novel-studio${window.location.pathname}`;
      window.location.href = `//${process.env.REACT_APP_WEB_DOMAIN}${pathname}${window.location.search}`;
    }
  }, []);

  useEffect(() => {
    const redirectToWebLogin = (): void => {
      if (window.location.hostname === "localhost") { return }
      const fromParam = `&from=${encodeURIComponent(
        `/novel-studio${location.pathname}`
      )}`;
      window.location.href = `//${process.env.REACT_APP_WEB_DOMAIN}/auth/login?nolayout=1${fromParam}`;
    };

    const loginByWebToken = async (stToken: string): Promise<void> => {
      await authUseCase.loginByToken(stToken).catch(() => {
        redirectToWebLogin();
      });
    };

    if (
      user !== undefined &&
      user === null &&
      !token &&
      location.pathname !== "/error" &&
      location.pathname !== "/logout"
    ) {
      if (user === null) {
        if (webToken) {
          // Check if we are coming from Studio and sign in with the same user
          loginByWebToken(webToken);
        } else {
          setTimeout(() => {
            if (user === null) {
              redirectToWebLogin();
            }
          }, 1000);
        }
      }
    } else if (user && user.isAnonymous) {
      setTimeout(() => {
        redirectToWebLogin();
      }, 1000);
    }
  }, [location.pathname, token, user, webToken]);

  return (
    <>
      <GlobalStyles />
      <UIContextProvider>
        <BrowserRouter basename="/novel-studio">
          <Layout>
            <ErrorBoundary history={history}>
              <AnimatedSwitch />
            </ErrorBoundary>
          </Layout>
        </BrowserRouter>
      </UIContextProvider>
    </>
  );
};
